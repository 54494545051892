import Container from "../../components/Container/Container";
import {useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {Button, Form, Space, Table, Tag} from "antd";
import {convertToInt, formatDateTime, numberFormat} from "../../helpers/helpers";
import getTransactions from "../../api/admin/transaction/getTransactions";
import Search from "../../components/Search/Search";
import searchPartner from "../../api/partner/searchPartner";
import dayjs from "dayjs";
import {ExportOutlined, ReloadOutlined} from "@ant-design/icons";

function Transaction() {
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const accessToken = useAuthHeader();
    const [form] = Form.useForm();
    const [options,setOptions] = useState([]);
    const [query,setQuery] = useState('');
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            align: 'center',
        },
        {
            title: 'Đối tác',
            dataIndex: 'username',
            align: 'center',
            render: (value, record) => {
                return <Tag>{record.user.username}</Tag>
            }
        },
        {
            title: 'Cộng/Trừ',
            dataIndex: 'io',
            align: 'center',
            filters: [
                {
                    text: 'Cộng tiền',
                    value: 1
                },
                {
                    text: 'Trừ tiền',
                    value: -1
                }
            ],
            render: (value) => {
                if (value === 1) {
                    return <Tag color={'gold'}>Cộng tiền</Tag>
                }
                return <Tag color={'red'}>Trừ tiền</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'description'
        },
        {
            title: 'Thời gian',
            dataIndex: 'createdAt',
            sorter: true,
            render: (value) => {
                return formatDateTime(value);
            }
        }
    ]

    const items = [
        {
            name: 'userId',
            type: 'search',
            placeholder: 'Nhập tên đối tác',
            options: options.map((item) => {
                return {
                    value: item.userId,
                    text: item.name
                }
            }),
            onSearch: (value) => {
                setQuery(value)
            }
        },
        {
            name: 'createdAt',
            type: 'date',
        }
    ]

    const fetchPartners = async () => {
        const response = await searchPartner(accessToken,query);
        if (response.success) {
            setOptions(response.data)
        }
    }
    const fetchData = async () => {
        setTableLoading(true);
        const response = await getTransactions(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount
            })
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    useEffect(() => {
        fetchPartners();
    }, [query])
    return (
        <Container>
            <Space
                style={{
                    marginBottom: '20px'
                }}
            >
                <Button type={'primary'} icon={<ExportOutlined/>} title={'Xuất file'}>Xuất file</Button>
            </Space>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 1200
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                summary={(data) => {

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                Tổng tiền
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}

export default Transaction;